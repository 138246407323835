<template>
  <div id="Main">
    <Title title="Ordem de Produção" />
    <Button
      text="Adicionar uma Nova Ordem de Produção"
      @add-fields-button="addFieldsButton()"
    />
    <div class="formBordas">
      <b-row class="aling-left">
        <b-col cols="12" sm="3" md="3" class="no-padding-bottom">
          <label>Data produção:</label>
          <DefaultDatePicker
            :maxDate="data_fim"
            :label="''"
            :data="data_inicio"
            @update:returnDate="data_inicio = $event"
          />
        </b-col>
        <b-col cols="12" sm="3" md="3" class="no-padding-bottom">
          <label>até:</label>
          <DefaultDatePicker
            :minDate="data_inicio"
            :disabled="disabledDataFim"
            :label="''"
            :data="data_fim"
            @update:returnDate="data_fim = $event"
          />
        </b-col>
        <b-col cols="12" sm="6" md="6">
          <label style="padding: 0px 0px">Status:</label>
          <v-select
            :items="opcoesStatus"
            item-text="status"
            item-value="code"
            v-model="statusProducao"
            solo
          />
        </b-col>
      </b-row>
      <ProductSearchComp @update:value="pesquisa = $event" />
      <b-row class="submit">
        <v-btn @click="pesquisar">Pesquisar</v-btn>
      </b-row>
    </div>
    <div class="formBordas2">
      <b-row class="submit">
        <b-col cols="12" sm="6" md="6" class="aling-center">
          <v-btn @click="createPDF" :disabled="setPrint">
            <v-icon small class="mr-2">
              mdi-file-document
            </v-icon>
            PDF
          </v-btn>
        </b-col>
        <!--<b-col cols="12" sm="6" md="6" class="aling-left">
          <v-btn @click="createExcel" :disabled="setPrint" >
            <v-icon 
              small
              class="mr-2">
                mdi-file-excel
            </v-icon>
            Excel
          </v-btn>
        </b-col>-->
      </b-row>
      <template>
        <div>
          <v-data-table
            ref="myTable"
            hide-default-footer
            :headers="headers"
            :items="itensEncontrados"
            class="elevation-1 v-table"
            :page.sync="page"
            :items-per-page="10"
            @page-count="pageCount = $event"
            :loading="setLoading"
            loading-text="Carregando..."
          >
            <template v-slot:no-data>
              <h4 class="mb-1 mt-1 no-data">
                {{ noData }}
              </h4>
            </template>

            <template v-slot:top>
              <v-dialog v-model="dialogDelete" max-width="670px">
                <v-card>
                  <v-card-title class="headline"
                    >Tem certeza que deseja excluir essa ordem de
                    produção?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancelar</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="confirmDeletItem"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:item.op_dta_agendamento="{item}">
              <span>
                {{ formataData(item.op_dta_agendamento) }}
              </span>
            </template>

            <template v-slot:item.op_quantidade_produzida="{item}">
              <span v-if="item.status[0].st_descricao == 'Finalizado'">
                {{ item.op_quantidade_produzida }}
              </span>
              <span v-else>-</span>
            </template>

            <template v-slot:item.custo_medio_recalculado="{item}">
              <span v-if="item.custo_medio_recalculado != undefined">
                {{ item.custo_medio_recalculado }}
              </span>
              <span v-else>-</span>
            </template>

            <template v-slot:item.actions="{item}">
              <v-row style="padding-left: 10px">
                <v-icon
                  small
                  class="mr-2"
                  title="Ver Composição"
                  @click="abrirComposicao(item)"
                >
                  mdi-magnify
                </v-icon>
                <v-icon
                  v-if="item.status[0].st_descricao == 'Aguardando produção'"
                  small
                  class="mr-2"
                  title="Editar"
                  @click="editItem(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  v-if="item.status[0].st_descricao != 'Finalizado'"
                  small
                  class="mr-2"
                  title="Informar Quantidade Realizada"
                  @click="abrirStatus(item)"
                >
                  mdi-arrow-right-thin-circle-outline
                </v-icon>
                <v-icon
                  small
                  class="mr-2"
                  title="Histórico"
                  @click="abrirHistorico(item)"
                >
                  mdi-clock-outline
                </v-icon>
                <v-icon
                  v-if="item.status[0].st_descricao == 'Aguardando produção'"
                  small
                  class="mr-2"
                  title="Excluir"
                  @click="deletItem(item)"
                >
                  mdi-delete
                </v-icon>
              </v-row>
            </template>
          </v-data-table>
          <div class="text-center pt-2">
            <v-pagination
              v-show="!this.setLoading && itensEncontrados.length > 0"
              align="center"
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </div>
        </div>
      </template>
    </div>
    <Modal
      id="addModal"
      :item="itemCompModal"
      :edit="edit"
      :limpaDados="limpaDados"
    />
    <StatusModal
      id="statusModal"
      :item="itemCompModal"
      :limpaDados="limpaDadosStatus"
    />
    <CompModal
      id="compModal"
      :item="itemCompModal"
      :limpaDados="limpaDadosComp"
    />
    <HistModal id="histModal" :item="itemHistModal" />
  </div>
</template>

<script>
import swal from 'sweetalert2';
import Title from '@/components/ordemProducao/Title';
import Button from '@/components/ordemProducao/Button';
import Modal from '@/components/ordemProducao/Modal';
import StatusModal from '@/components/ordemProducao/StatusModal';
import CompModal from '@/components/ordemProducao/CompModal';
import HistModal from '@/components/ordemProducao/HistModal';
import moment from 'moment';
import apiInstance from '../../services/api/config';
import DefaultDatePicker from '@/components/generics/DefaultDatePicker';
import ProductSearchComp from '@/components/search-components/ProductSearchComp';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  name: 'Main',
  components: {
    ProductSearchComp,
    DefaultDatePicker,
    Button,
    Title,
    Modal,
    StatusModal,
    CompModal,
    HistModal,
  },
  data() {
    return {
      user: '',
      limitRelatorio: 0,
      dialogDelete: false,
      limpaDados: 0,
      limpaDadosComp: 0,
      limpaDadosStatus: 0,
      edit: 0,
      data_inicio: null,
      data_fim: null,
      disabledDataFim: true,
      data_inicio_PDF: null,
      data_fim_PDF: null,
      setLoading: false,
      pageCount: 0,
      page: 1,
      setPrint: true,
      totalRows: 0,
      pesquisa: {},
      pesquisaPDF: {},
      itensEncontrados: [],
      itemCompModal: {
        op_codigo: '',
        status: [{st_descricao: ''}],
        finished_product: {
          product_variety: {
            product_mobile: {
              pro_descricao: '',
            },
          },
          measurement_unit: {
            unm_descricao: '',
          },
        },
      },
      itemHistModal: {
        finished_product: {
          product_complete_name: '',
          product_variety: {
            product_mobile: {
              pro_codigo: '',
              pro_descricao: '',
            },
          },
        },
      },
      statusProducao: -1,
      statusProducaoPDF: -1,
      opcoesStatus: [
        {status: '--SELECIONE--', code: -1},
        {status: 'Aguardando Produção', code: 1},
        {status: 'Em Produção', code: 2},
        {status: 'Finalizado', code: 3},
      ],
      headers: [
        {text: 'Cód. ordem produção', value: 'op_codigo', width: '8%'},
        {text: 'Cód. produto', value: 'op_fk_pve_final'},
        {
          text: 'Descrição',
          value:
            'finished_product.product_variety.product_mobile.pro_descricao',
        },
        {
          text: 'Unidade de medida',
          value: 'finished_product.measurement_unit.unm_descricao',
        },
        {text: 'Qtde prevista', value: 'op_quantidade', align: 'end'},
        {
          text: 'Qtde produzida',
          value: 'op_quantidade_produzida',
          align: 'end',
        },
        //{text: 'Custo médio (R$)', value: 'finished_product.product_variety.prv_custo_total', sortable: false},
        //{text: 'Custo médio recalculado (R$)', value: 'custo_medio_recalculado', sortable: false},
        {text: 'Status', value: 'status[0].st_descricao'},
        {text: 'Data produção', value: 'op_dta_agendamento'},
        {text: 'Opções', value: 'actions', sortable: false, width: '10%'},
      ],
      noData: 'Entre com as informações de pesquisa',
      pesquisaProducao: '',
    };
  },
  watch: {
    data_inicio() {
      if (this.data_inicio != null) {
        this.disabledDataFim = false;
      }
    },
  },
  methods: {
    addFieldsButton() {
      this.edit = 0;
      this, (this.limpaDados += 1);
      this.$bvModal.show('addModal');
    },
    editItem(item) {
      this.itemCompModal = item;
      this.edit += 1;
      this.$bvModal.show('addModal');
    },
    abrirComposicao(item) {
      this.itemCompModal = item;
      this.limpaDadosComp += 1;
      this.$bvModal.show('compModal');
    },
    abrirHistorico(item) {
      this.itemHistModal = item;
      this.$bvModal.show('histModal');
    },
    abrirStatus(item) {
      this.itemCompModal = item;
      this.limpaDadosStatus += 1;
      this.$bvModal.show('statusModal');
    },
    deletItem(item) {
      this.editedIndex = this.itensEncontrados.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    confirmDeletItem() {
      apiInstance
        .delete('/production-order/' + this.editedItem.op_codigo)
        .then(() => {
          swal.fire({
            title: '',
            text: 'Ordem de produção excluída com sucesso !',
            icon: 'success',
            timer: 2000,
          });
        })
        .catch(error => {
          console.log(error);
        });
      this.itensEncontrados.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.editedItem);
        this.editedIndex = -1;
      });
    },
    setPesquisa() {
      if (this.pesquisa.pve_codigo != undefined) {
        this.pesquisaProducao = 'op_fk_pve_final=' + this.pesquisa.pve_codigo;
      }
      if (this.pesquisaProducao != '' && this.statusProducao != -1) {
        this.pesquisaProducao = this.pesquisaProducao + '&';
      }
      if (this.statusProducao != -1) {
        this.pesquisaProducao =
          this.pesquisaProducao + 'st_codigo=' + this.statusProducao;
      }
      if (this.pesquisaProducao != '' && this.data_inicio != null) {
        this.pesquisaProducao = this.pesquisaProducao + '&';
      }
      if (this.data_inicio != null) {
        this.pesquisaProducao =
          this.pesquisaProducao +
          'initial_date=' +
          this.data_inicio +
          '%2000%3A00%3A00';
      }
      if (this.pesquisaProducao != '' && this.data_fim != null) {
        this.pesquisaProducao = this.pesquisaProducao + '&';
      }
      if (this.data_fim != null) {
        this.pesquisaProducao =
          this.pesquisaProducao +
          'final_date=' +
          this.data_fim +
          '%2023%3A59%3A59';
      }
    },
    pesquisar() {
      this.pesquisaPDF = null;
      this.statusProducaoPDF = -1;
      this.data_inicio_PDF = null;
      this.data_fim_PDF = null;
      this.setLoading = true;
      if (this.itensEncontrados != []) {
        this.itensEncontrados = [];
      }
      this.pesquisaProducao = '';
      this.setPesquisa();
      apiInstance
        .get('/production-order?' + this.pesquisaProducao)
        .then(res => {
          this.setLoading = false;
          this.setPrint = false;
          this.itensEncontrados = (res.data?.data || []).map(item => ({
            ...item,
            op_quantidade: parseFloat(item.op_quantidade),
            op_quantidade_produzida: parseFloat(item.op_quantidade_produzida),
          }));
        });
      this.data_inicio_PDF = this.data_inicio;
      this.data_fim_PDF = this.data_fim;
      this.pesquisaPDF = {
        pve_codigo: this.pesquisa.pve_codigo,
        product_complete_name: this.pesquisa.product_complete_name,
      };
      this.noData = 'Nenhum item encontrado!';
      this.statusProducaoPDF = this.statusProducao;
      /*
      this.pesquisa = {};
      this.data_inicio = null;
      this.data_fim = null;
      this.statusProducao = -1;
      this.limpaDados += 1;
      this.limpaDadosData += 1;
      */
    },
    formataData(data) {
      return moment(String(data)).format('DD/MM/YYYY');
    },
    createPDF() {
      if (this.limitRelatorio == 0) {
        this.limitRelatorio++;
        apiInstance.get('user/me/person').then(res => {
          this.user = res.data.pes_nome;

          const source = this.$refs['myTable'];
          let data_inicio_PDF = null;
          let data_fim_PDF = null;
          let statusProducaoPDF = null;
          let produto = null;
          const user = this.user;
          if (this.data_inicio_PDF != null) {
            data_inicio_PDF = this.formataData(this.data_inicio_PDF);
            data_fim_PDF = this.formataData(this.data_fim_PDF);
          }
          if (this.statusProducaoPDF == 1) {
            statusProducaoPDF = 'Aguardando Produção';
          } else if (this.statusProducaoPDF == 2) {
            statusProducaoPDF = 'Em Produção';
          } else if (this.statusProducaoPDF == 3) {
            statusProducaoPDF = 'Finalizado';
          }
          if (this.pesquisaPDF.pve_codigo != undefined) {
            produto = {
              cod: this.pesquisaPDF.pve_codigo,
              desc: this.pesquisaPDF.product_complete_name,
            };
          }
          let marginTopPDF = 20;
          let marginTabela = marginTopPDF + 15;
          if (data_inicio_PDF != null) marginTabela += 6;
          if (statusProducaoPDF != null) marginTabela += 6;
          if (produto != null) marginTabela += 6;
          const rows = [];
          let temp = [];
          source.items.forEach(element => {
            if (element.status[0].st_descricao == 'Finalizado') {
              temp = [
                element.op_codigo,
                element.op_fk_pve_final,
                element.finished_product.product_variety.product_mobile
                  .pro_descricao,
                element.finished_product.measurement_unit.unm_descricao,
                element.op_quantidade,
                element.op_quantidade_produzida,
                //element.finished_product.product_variety.prv_custo_total,
                //element.finished_product.product_variety.prv_custo_total,
                element.status[0].st_descricao,
                this.formataData(element.op_dta_agendamento),
              ];
            } else {
              temp = [
                element.op_codigo,
                element.op_fk_pve_final,
                element.finished_product.product_variety.product_mobile
                  .pro_descricao,
                element.finished_product.measurement_unit.unm_descricao,
                element.op_quantidade,
                (element.op_quantidade_produzida = '-'),
                //element.finished_product.product_variety.prv_custo_total,
                //element.finished_product.product_variety.prv_custo_total,
                element.status[0].st_descricao,
                this.formataData(element.op_dta_agendamento),
              ];
            }

            rows.push(temp);
          });
          const doc = new jsPDF({
            orientation: 'landscape',
          });
          const totalPagesExp = '{total_pages_count_string}';
          doc.autoTable({
            head: [
              [
                {
                  content: 'Cód. ordem produção',
                  styles: {fillColor: [131, 140, 145]},
                },
                {content: 'Cód. produto', styles: {fillColor: [131, 140, 145]}},
                {content: 'Descrição', styles: {fillColor: [131, 140, 145]}},
                {
                  content: 'Unidade de medida',
                  styles: {fillColor: [131, 140, 145]},
                },
                {
                  content: 'Qtde prevista',
                  styles: {fillColor: [131, 140, 145]},
                },
                {
                  content: 'Qtde produzida',
                  styles: {fillColor: [131, 140, 145]},
                },
                //{content:'Custo médio (R$)',             styles:{fillColor:[131,140,145]}},
                //{content:'Custo médio recalculado (R$)', styles:{fillColor:[131,140,145]}},
                {content: 'Status', styles: {fillColor: [131, 140, 145]}},
                {
                  content: 'Data produção',
                  styles: {fillColor: [131, 140, 145]},
                },
              ],
            ],
            body: rows,
            startY: marginTabela,
            didDrawPage: function(data) {
              if (doc.internal.getNumberOfPages() == 1) {
                // Header
                doc.setFontSize(20);
                doc.setTextColor(40);
                doc.text(
                  'Relatório de ordem de produção',
                  data.settings.margin.left + 85,
                  marginTopPDF,
                );

                //Info
                doc.setFontSize(14);
                marginTopPDF += 10;
                if (data_inicio_PDF != null) {
                  marginTopPDF += 6;
                  if (data_fim_PDF == 'Data inválida') {
                    doc.text(
                      'Data de produção: A partir de ' + data_inicio_PDF,
                      data.settings.margin.left + 0,
                      marginTopPDF,
                    );
                  } else {
                    doc.text(
                      'Data de produção: ' +
                        data_inicio_PDF +
                        ' até ' +
                        data_fim_PDF,
                      data.settings.margin.left + 0,
                      marginTopPDF,
                    );
                  }
                }
                if (statusProducaoPDF != null) {
                  marginTopPDF += 6;
                  doc.text(
                    'Status: ' + statusProducaoPDF,
                    data.settings.margin.left + 0,
                    marginTopPDF,
                  );
                }
                if (produto != null) {
                  marginTopPDF += 6;
                  doc.text(
                    'Produto: ' + produto.cod + ' - ' + produto.desc,
                    data.settings.margin.left + 0,
                    marginTopPDF,
                  );
                }
              }

              // Footer
              let str = 'Página ' + doc.internal.getNumberOfPages();
              // Total page number plugin only available in jspdf v1.0+
              if (typeof doc.putTotalPages === 'function') {
                str = str + ' de ' + totalPagesExp;
              }
              str =
                str +
                ' - Impresso em ' +
                moment().format('L') +
                ' ' +
                moment().format('LT') +
                ' por ' +
                user;
              doc.setFontSize(10);

              // jsPDF 1.4+ uses getWidth, <1.4 uses .width
              const pageSize = doc.internal.pageSize;
              const pageHeight = pageSize.height
                ? pageSize.height
                : pageSize.getHeight();
              doc.text(str, data.settings.margin.left, pageHeight - 10);
            },
          });
          if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
          }
          doc.save('Relatório de Ordem de Produção' + '.pdf');
          produto = null;
          statusProducaoPDF = -1;
          data_inicio_PDF = null;
          data_fim_PDF = null;
          this.limitRelatorio = 0;
        });
      }
    },
    createExcel() {
      if (this.limitRelatorio == 0) {
        this.limitRelatorio++;
        const table = this.$refs['myTable'];

        const headers =
          'Cód. ordem produção,Cód. produto,Descrição,Unidade de medida,Qtde prevista,Qtde produzida,Status,Data produção\r\n';
        let row = [];
        const rows = [];
        let temp = [];
        table.items.forEach(element => {
          if (element.status[0].st_descricao == 'Finalizado') {
            temp = [
              element.op_codigo,
              element.op_fk_pve_final,
              element.finished_product.product_variety.product_mobile
                .pro_descricao,
              element.finished_product.measurement_unit.unm_descricao,
              element.op_quantidade,
              element.op_quantidade_produzida,
              //element.finished_product.product_variety.prv_custo_total,
              //element.finished_product.product_variety.prv_custo_total,
              element.status[0].st_descricao,
              this.formataData(element.op_dta_agendamento),
            ];
          } else {
            temp = [
              element.op_codigo,
              element.op_fk_pve_final,
              element.finished_product.product_variety.product_mobile
                .pro_descricao,
              element.finished_product.measurement_unit.unm_descricao,
              element.op_quantidade,
              (element.op_quantidade_produzida = '-'),
              //element.finished_product.product_variety.prv_custo_total,
              //element.finished_product.product_variety.prv_custo_total,
              element.status[0].st_descricao,
              this.formataData(element.op_dta_agendamento),
            ];
          }

          rows.push(temp);
        });

        let csvContent = 'data:text/csv;charset=utf-8,';

        if (this.data_inicio != null) {
          if (this.data_fim == null) {
            csvContent +=
              'Data de produção:,A partir de ' +
              this.formataData(this.data_inicio) +
              '\r\n';
          } else {
            csvContent +=
              'Data de produção:,' +
              this.formataData(this.data_inicio) +
              ' até ' +
              this.formataData(this.data_fim) +
              '\r\n';
          }
        }
        if (this.statusProducao != -1) {
          if (this.statusProducao == 1) {
            csvContent += 'Status:,' + 'Aguardando Produção' + '\r\n';
          } else if (this.statusProducao == 2) {
            csvContent += 'Status:,' + 'Em Produção' + '\r\n';
          } else if (this.statusProducao == 3) {
            csvContent += 'Status:,' + 'Finalizado' + '\r\n';
          }
        }
        if (this.pesquisa.pve_codigo != null) {
          csvContent +=
            'Produto:,' +
            this.pesquisa.pve_codigo +
            ' - ' +
            this.pesquisa.product_complete_name +
            '\r\n';
        }

        if (csvContent != 'data:text/csv;charset=utf-8,') {
          csvContent += '\r\n';
        }

        csvContent += headers;
        rows.forEach(function(rowArray) {
          row = rowArray.join(',');
          csvContent += row + '\r\n';
        });

        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'Relatório de Ordem de Produção.csv');
        document.body.appendChild(link);
        link.click();
        this.limitRelatorio = 0;
      }
    },
  },
};
</script>

<style scoped>
.formBordas {
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 0.25em;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}
.formBordas2 {
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 0.25em;
  padding: 20px 0px 0px 0px;
  margin-bottom: 20px;
}
.aling-left {
  text-align: left;
}
.aling-right {
  text-align: right;
}
.submit {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 10px;
}
.no-padding {
  padding: 0px 5px;
}
.no-padding-bottom {
  padding-bottom: 0px;
}
.no-data {
  font-size: 15px;
}
</style>
