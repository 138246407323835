<template>
  <div id="compModal">
    <b-modal
      :id="id"
      okVariant="danger"
      @ok="handleOk"
      @cancel="handleCancel"
      okTitle="Salvar"
      cancelTitle="Cancelar"
      cancelVariant="outline-secondary"
      centered
      header-bg-variant="dark"
      header-text-variant="light"
      ref="modal"
      size="xl"
      :title="'Alterar status da ordem do produção #' + item.op_codigo"
    >
      <template>
        <div class="formBordas">
          <div style="padding-bottom:5px">
            <b>Produto acabado: </b
            >{{ item.finished_product.product_complete_name }} <br />
          </div>
          <div>
            <b-row>
              <b-col cols="12" sm="6" md="6" style="margin-top: 20px">
                <span>
                  <b>Status atual: </b> {{ item.status[0].st_descricao }}
                </span>
                <div class="label"><br /><b>alterar para:</b></div>
                <v-select
                  :items="opcoesStatus"
                  item-text="status"
                  item-value="code"
                  v-model="alteraStatus"
                  solo
                />
              </b-col>
              <b-col cols="12" sm="6" md="6">
                <v-textarea
                  style="margin-top: 0px"
                  solo
                  disabled
                  v-model="statusMensage"
                >
                </v-textarea>
              </b-col>
            </b-row>
            <div v-show="alteraStatus == 3">
              <b-row>
                <b-col cols="12" sm="3" md="3" class="no-padding center">
                  <div class="label"><b>Quantidade prevista: </b></div>
                  <v-text-field
                    class="right-input"
                    solo
                    disabled
                    v-model="item.op_quantidade"
                  >
                  </v-text-field>
                </b-col>
                <b-col cols="12" sm="3" md="3" class="no-padding center">
                  <div class="label"><b>Quantidade produzida: </b></div>
                  <v-text-field
                    class="right-input"
                    type="number"
                    step="0.01"
                    solo
                    v-model="quantidadeProduzida"
                  >
                  </v-text-field>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" class="no-padding">
                  <div class="label"><b>Lote: </b></div>
                  <v-select
                    :items="batchItems"
                    v-model="selectedBatch"
                  ></v-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" sm="12" md="12" class="no-padding">
                  <div class="label"><b>Observação: </b></div>
                  <v-textarea solo v-model="item.op_observacao" />
                </b-col>
              </b-row>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import apiInstance from '../../services/api/config';
import swal from 'sweetalert2';

export default {
  name: 'StatusModal',
  props: ['id', 'idEdit', 'item', 'limpaDados'],
  data() {
    return {
      statusMensage: '',
      alteraStatus: '',
      opcoesStatus: [],
      quantidadeProduzida: '',
      batchs: [],
      selectedBatch: null,
    };
  },
  computed: {
    batchItems() {
      return (this.batchs || []).map(batch => ({
        value: batch.lot_codigo,
        text: batch.lot_descricao,
      }));
    },
    quantidade() {
      return typeof this.quantidadeProduzida === 'string'
        ? parseFloat(this.quantidadeProduzida.replace(',', '.'))
        : this.quantidadeProduzida;
    },
  },
  watch: {
    limpaDados() {
      this.handleCancel();
    },
    item() {
      if (this.item.status[0].st_codigo == 1) {
        this.opcoesStatus = [{status: 'Em Produção', code: 2}];
      }
      if (this.item.status[0].st_codigo == 2) {
        this.opcoesStatus = [
          {status: 'Aguardando Produção', code: 1},
          {status: 'Finalizado', code: 3},
        ];
      }
      if (this.item.status[0].st_codigo == 3) {
        this.opcoesStatus = [];
      }
    },
    alteraStatus() {
      if (this.item.status[0].st_codigo == 1 && this.alteraStatus == 2) {
        this.statusMensage = 'Estoque reservado para produção';
      } else if (this.item.status[0].st_codigo == 2 && this.alteraStatus == 1) {
        this.statusMensage =
          'Estoque reservado será devolvido para o estoque empresa';
      } else if (this.item.status[0].st_codigo == 2 && this.alteraStatus == 3) {
        this.getBatchs();

        this.statusMensage =
          'Estoque utilizado na produção será baixado\nProduto acabado será adicionado no estoque empresa';
      } else {
        this.statusMensage = '';
      }
    },
  },
  methods: {
    async getBatchs() {
      try {
        const {data} = await apiInstance.get(
          `/production-order/lotes/${this.item.finished_product.pve_codigo}`,
        );

        this.batchs = data;
      } catch (e) {
        console.log(e);
      }
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();

      if (this.alteraStatus == '') {
        swal.fire({
          title: '',
          text: 'Por favor, informe o novo status para continuar.',
          icon: 'warning',
        });
        return;
      } else if (this.alteraStatus == 3) {
        if (!this.quantidade) {
          swal.fire({
            title: '',
            text: 'Por favor, informe a quantidade produzida para continuar.',
            icon: 'warning',
          });
          return;
        } else if (!this.selectedBatch) {
          swal.fire({
            title: '',
            text: 'Por favor, informe o lote do produto.',
            icon: 'warning',
          });
        } else if (this.quantidade == this.item.op_quantidade) {
          this.handleOkConfirma();
        } else if (this.quantidade < this.item.op_quantidade) {
          swal
            .fire({
              title: '',
              text:
                'Quantidade produzida menor que a prevista! Deseja confirmar?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              cancelButtonText: 'Voltar',
              confirmButtonText: 'Confirmar!',
            })
            .then(result => {
              if (result.isConfirmed) {
                this.handleOkConfirma();
              }
            });
          return;
        } else if (this.quantidade > this.item.op_quantidade) {
          swal
            .fire({
              title: '',
              text:
                'Quantidade produzida maior que a prevista! Deseja confirmar?',
              icon: 'question',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              cancelButtonText: 'Voltar',
              confirmButtonText: 'Confirmar!',
            })
            .then(result => {
              if (result.isConfirmed) {
                this.handleOkConfirma();
              }
            });
          return;
        }
      } else {
        this.quantidadeProduzida = this.item.op_quantidade;
        this.handleOkConfirma();
      }
    },
    handleOkConfirma() {
      swal.fire({
        title: '',
        html: '<span class="mdi mdi-spin mdi-loading"></span>' + ' Salvando...',
        showConfirmButton: false,
      });
      const obj = {
        st_codigo: this.alteraStatus,
        op_quantidade_produzida: this.quantidade,
        op_observacao: this.item.op_observacao,
        lot_codigo: this.selectedBatch,
      };

      apiInstance
        .put('/production-order/' + this.item.op_codigo + '/status', obj)
        .then(() => {
          swal
            .fire({
              title: '',
              text: 'Status atualizado com sucesso !',
              icon: 'success',
              timer: 2000,
            })
            .then(willDelete => {
              if (willDelete) {
                location.reload();
              }
            });
        })
        .catch(error => {
          console.log(error);
        });
      this.alteraStatus = '';
    },
    handleCancel() {
      this.alteraStatus = '';
    },
  },
};
</script>

<style scoped>
input:focus {
  /*border-color: #28a745 !important;*/
  box-shadow: none !important;
}

.formBordas {
  border: solid medium rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.no-padding {
  padding: 0px 12px;
}

.label {
  padding-bottom: 10px;
}

.right-input >>> input {
  text-align: right;
}
</style>
